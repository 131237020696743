import type {
  EventTrackCTA,
  OptionsListType,
  TrackViewTabName,
} from './useVCMTrack.type';
import type { EpisodesListType } from '../../../context/vcmStore/vcmStore';
import type { Content } from '../../../@schema';

import { useCallback, useMemo } from 'react';

import { sendTrack } from '../../../utils/track';
import { QueryParams } from '../../../utils/query-params';
import { useStaticData } from '../../../context/static-data-context';
import { Utils } from '../../../utils';
import tracksPaths from '../../../constants/paths-tracks.json';
import { useVCMStore } from '../../../context/vcmStore/vcmStore';
import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import COMMONS from '../../../constants/commons.json';
import { useUserContext } from '../../context/user-context';
import { suscriptionsTrackName } from '../../../@util/webview/create-view-context/constants';

const { VCM } = tracksPaths;
const { loyaltyTrack, samplingTrack } = suscriptionsTrackName;

export const optionList = {
  CONTINUOUS_WATCH: 'continuous_watch_free',
  WATCH_FREE: 'watch_free',
  LOGIN: 'login',
  LOGINMODAL: 'login_modal',
  KNOWMORE: 'know_more',
};

type DynamicType<T = unknown> = Record<
  string,
  Record<string, Array<string> | number | string> | T | [OptionsListType]
>;

export const useVcmTrack = <T extends DynamicType>(
  currentTab?: TrackViewTabName,
  data?: Content['content'],
) => {
  const { restrictions, permission } = useRestriction();
  const { url, guest_id, experiment, isKeepWatching, siteId } = useStaticData();

  const { nbcuUserGuest, nbcuUserLoggedIn } = permission;

  const { tabSelected, episodesList, setEpisodesList } = useVCMStore();
  const { preferences, loyalty, sampling } = useUserContext();

  const isSampling =
    sampling.isReadyMeliPlus ||
    sampling.isVinculatedToMeliPlus ||
    sampling.isUpgradeToMeliPlus;

  const isLoyalty =
    loyalty.isReadyMeliPlus ||
    loyalty.isVinculatedToMeliPlus ||
    loyalty.isUpgradeToMeliPlus;

  const sourcePayload = useCallback(
    (isTrack = true) => {
      const makeSource = QueryParams.filterParamsWithCustomPrefix(url!);

      let makeSourceSnakeCase = Utils.transformCamelCaseToSnakeCase(
        makeSource as Record<string, string>,
      );

      if (isTrack && makeSourceSnakeCase.origin) {
        makeSourceSnakeCase = {
          ...makeSourceSnakeCase,
          type: makeSourceSnakeCase.origin,
        };

        delete makeSourceSnakeCase.origin;
      }

      return makeSourceSnakeCase;
    },
    [url],
  );

  function makeUpgradeLoyaltyLabel(): string {
    if (siteId === 'MLA' || siteId === 'MCO') {
      const level = loyalty?.isUpgradeToMeliPlus?.level;

      if (level && level < 6) {
        return loyaltyTrack.suscription;
      }

      return loyaltyTrack.activation;
    }

    if (loyalty.isUpgradeToMeliPlus?.product_code === 'meli_essential') {
      return loyaltyTrack.upgrade;
    }

    return loyaltyTrack.suscription;
  }

  function createOptionsList(): Array<string> {
    const opt: Array<string> = [];

    if (isSampling && sampling.isReadyMeliPlus) {
      opt.push(samplingTrack.watch);
    }

    if (isSampling && sampling.isVinculatedToMeliPlus) {
      opt.push(samplingTrack.linking);
    }

    if (isSampling && sampling.isUpgradeToMeliPlus) {
      opt.push(samplingTrack.suscription);
    }

    if (isLoyalty && loyalty.isReadyMeliPlus) {
      opt.push(loyaltyTrack.watch);
    }

    if (isLoyalty && loyalty.isVinculatedToMeliPlus) {
      opt.push(loyaltyTrack.linking);
    }

    if (isLoyalty && loyalty.isUpgradeToMeliPlus) {
      const label = makeUpgradeLoyaltyLabel();

      opt.push(label);
    }

    if (nbcuUserGuest) {
      opt.push(
        ...[optionList.LOGIN, optionList.KNOWMORE, optionList.LOGINMODAL],
      );
    }

    if (nbcuUserLoggedIn) {
      opt.push(optionList.KNOWMORE);
    }

    if (!isLoyalty && !nbcuUserGuest && !nbcuUserLoggedIn) {
      const defaultOption = isKeepWatching
        ? optionList.CONTINUOUS_WATCH
        : optionList.WATCH_FREE;

      opt.push(defaultOption);
    }

    return opt;
  }

  const getDataSeason = () => {
    const seasonData = {
      seansonNumber: '',
      seasonId: '',
    };

    if (data?.type === 'SHOW') {
      seasonData.seansonNumber = data.playing_season.number.toString();
      seasonData.seasonId = data.playing_season.id;
    }

    if (data?.type === 'EPISODE') {
      seasonData.seansonNumber = data.season_id;
      seasonData.seasonId = data.season_number.toString();
    }

    return seasonData;
  };

  const obj = useMemo(
    () => ({
      source: Utils.transformKeys(sourcePayload(), 'origin_'),
      vertical: COMMONS.VERTICAL_TRACK_VALUE,
      page: 1,
      guest_user_id: guest_id,
      experiment: experiment || undefined,
      options_list: createOptionsList(),
      contents_list: episodesList || [],
      runtime:
        data?.type === 'SHOW'
          ? data?.playing_episode.duration
          : data?.duration || 0,
      restrictions_list: restrictions || [],
      season_id: data?.type === 'MOVIE' ? 'NA' : getDataSeason().seasonId,
      season_number:
        data?.type === 'MOVIE' ? 'NA' : getDataSeason().seansonNumber,
    }),
    [restrictions, url, data, episodesList],
  );

  const runViewTrack = useCallback(
    (data: T) => {
      setEpisodesList(data.contents_list as Array<EpisodesListType>);
      sendTrack({
        path: VCM.VIEW_PAGE,
        typeEvent: 'view',
        eventData: { selected_tab: currentTab || '', ...obj, ...data },
      });
    },
    [restrictions, obj, episodesList],
  );

  const runEventTrack = useCallback(
    (data: T) => {
      const { experiment, ...restObjOfTracks } = obj;

      const payload = data;
      const [optionList] = restObjOfTracks.options_list;

      if (!payload.selected_option) {
        (payload as unknown as EventTrackCTA).selected_option = optionList;
      }

      sendTrack({
        path: VCM.SELECT,
        typeEvent: 'event',
        eventData: {
          ...restObjOfTracks,
          ...payload,
          restrictions_list: restrictions || [],
          pg_rating: preferences?.parental_control || 'NA',
          selected_tab: tabSelected,
        },
      });
    },
    [tabSelected, restrictions, obj, episodesList],
  );

  return { runViewTrack, runEventTrack, sourcePayload } as const;
};
