import type {
  Recommendation as BaseRecommendation,
  RecommendationExclusive,
  Season,
} from '../../@schema';

import { create } from 'zustand';

export type EpisodesListType = {
  content_id: string;
  content_type: string;
  type: string;
};

export type VCMStoreState = {
  isAbleToReload: boolean;
  setIsAbleToReload: (isAbleToReload: boolean) => void;
  tabSelected: string;
  setTabSelected: (tabSelected: string) => void;
  setCacheRecommendations: (data: BaseRecommendation | null) => void;
  cachedRecommendations: BaseRecommendation | null;
  setCacheExclusiveContent: (data: RecommendationExclusive | null) => void;
  cachedExclusiveContent: RecommendationExclusive | null;
  cacheSeasons: Season | null;
  setCacheSeasons: (data: Season | null) => void;
  setEpisodesList: (episodesList: Array<EpisodesListType>) => void;
  episodesList: Array<EpisodesListType>;
};

export const useVCMStore = create<VCMStoreState>((set) => ({
  isAbleToReload: false,
  isAbleToTrack: false,
  tabSelected: '',
  setTabSelected: (tabSelected) => set((state) => ({ ...state, tabSelected })),
  setIsAbleToReload: (isAbleToReload) =>
    set((state) => ({
      ...state,
      isAbleToReload,
    })),
  cachedRecommendations: null,
  setCacheRecommendations: (data: BaseRecommendation | null) =>
    set((state) => ({
      ...state,
      cachedRecommendations: data,
    })),
  cachedExclusiveContent: null,
  setCacheExclusiveContent: (data: RecommendationExclusive | null) =>
    set((state) => ({
      ...state,
      cachedExclusiveContent: data,
    })),

  setCacheSeasons: (data: Season | null) =>
    set((state) => ({ ...state, cacheSeasons: data })),
  cacheSeasons: null,
  setEpisodesList: (episodes: Array<EpisodesListType>) =>
    set((state) => ({ ...state, episodesList: episodes })),
  episodesList: [],
}));
