import type { PillProps } from './Pill.types';

import { Pill as PillAndes } from '@andes/badge';
import classNames from 'classnames';

import { Icon } from '../Icon';
import { Utils } from '../../utils';

export const Pill: React.FC<PillProps> = ({
  label,
  color,
  textColor,
  type,
  icon,
  className,
  ...props
}) => {
  let newSize;

  switch (props.size) {
    case 'small':
      newSize = 'S';

      break;
    case 'large':
      newSize = 'L';

      break;

    default:
      newSize = 'M';
  }

  return (
    <PillAndes
      {...props}
      className={classNames(className, {
        'left-pill-exclusive': icon?.includes('mplay_blocked'),
      })}
      secondaryColor={{
        background: color || '',
        text: textColor,
      }}
    >
      {icon && <Icon content={icon} className="pill-icon" />}
      <span>{Utils.formatPillLabel(label, newSize)}</span>
    </PillAndes>
  );
};
