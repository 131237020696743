import type { ContentLockModalProps } from './ContentLockModal.type';
import type { EventTrackCTA } from '../../../@domain/hooks/useVCMTrack/useVCMTrack.type';

import { Modal as AndesModal } from '@andes/modal';
import { useI18n } from 'nordic/i18n';
import { Typography } from '@andes/typography';
import { Button } from '@andes/button';

import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import { useVcmTrack, optionList } from '../../../@domain/hooks/useVCMTrack';
import { useFullVCMNavigation } from '../../../@domain/hooks/useFullVCMNavigation';
import { useVCMStore } from '../../../context/vcmStore/vcmStore';

export const ContentLockModal = ({
  data,
  currency,
  userContext,
}: ContentLockModalProps) => {
  const { i18n } = useI18n();
  const { navigateToLogin } = useFullVCMNavigation();
  const { isOpenModalLock, setIsOpenModalLock } = useRestriction();
  const { episodesList } = useVCMStore();
  const { runEventTrack } = useVcmTrack<EventTrackCTA>();

  const tracks = {
    content_id: data?.id || '',
    content_type: data?.type.toLowerCase() || '',
    genres: data?.genres || [],
    pg_rating: userContext?.preferences.parental_control || '',
    contents_list: episodesList || [],
    content_tags: [],
    selected_option: optionList.LOGINMODAL,
  };

  const handleLogin = (): void => {
    runEventTrack(tracks);
    navigateToLogin(data?.id || '');
  };

  return (
    <AndesModal
      title={i18n.gettext('¿Cómo accedo a ver el contenido exclusivo?')}
      open={isOpenModalLock}
      onClose={() => setIsOpenModalLock(isOpenModalLock)}
      type="card"
      className="modal-content-lock"
    >
      <Typography className="custom-typo-user-guest">
        {i18n.gettext(
          'Para disfrutar el contenido exclusivo de Universal+ Esencial tienes que tener una o más compras en Mercado Libre por {0} en los últimos 90 días.',
          currency,
        )}
      </Typography>

      <div className="content-button">
        <Button fullWidth size="large" onClick={handleLogin}>
          {i18n.gettext('Iniciar sesión')}
        </Button>
      </div>
    </AndesModal>
  );
};
