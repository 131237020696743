import type { Context } from 'react';
import type { UserDataProviderProps } from './user-context.types';

import { createContext, useContext } from 'react';

const defaultContext: UserDataProviderProps = {
  progress: {},
  preferences: {
    audio_language: '',
    parental_control: 'ATP',
    subtitle_language: '',
  },
  subscriptions: {
    content_providers: {
      DISNEYPLUS: {
        status: '',
        product_code: '',
        meli_tiers: false,
        meli_plus: false,
        level: null,
      },
      MAX: {
        status: '',
        product_code: '',
        meli_tiers: false,
        meli_plus: false,
        level: null,
      },
    },
  },
  loyalty: {
    isUpgradeToMeliPlus: null,
    isVinculatedToMeliPlus: null,
    isReadyMeliPlus: null,
  },
  sampling: {
    isUpgradeToMeliPlus: null,
    isVinculatedToMeliPlus: null,
    isReadyMeliPlus: null,
  },
};

export const UserDataContext: Context<UserDataProviderProps> =
  createContext<UserDataProviderProps>(defaultContext);

export const { Provider: UserContextProvider } = UserDataContext;

export const useUserContext = () => useContext(UserDataContext);
