import {
  level6Params,
  withModalParams,
  newVinculateParams,
} from './params-utils';

export const originParams = '#origin=mplay';

export const queryParamsDisney =
  '?placement=contentFeed&placementDetail=mixedContentFeed&distributionPartner=meli';

// todos con nivel 6
export const pathsToSuscribe: Record<string, string> = {
  MLC: `/suscripciones/melimas${originParams}&${level6Params()}`,
  MLM: `/suscripciones/melimas${originParams}&${withModalParams()}`,
  MLB: `/assinaturas/melimais${originParams}&${withModalParams()}`,
  MLA: `/suscripciones/nivel-6${originParams}&${level6Params()}`,
  MCO: `/suscripciones/nivel-6${originParams}&${level6Params()}`,
};

export const pathsToVinculate: Record<string, string> = {
  MLB: `/assinaturas/melimais/admin${originParams}&${newVinculateParams()}`,
  MLA: `/suscripciones/nivel-6/admin${originParams}&${newVinculateParams()}`,
  MLC: `/suscripciones/melimas/admin${originParams}&${newVinculateParams()}`,
  MCO: `/suscripciones/nivel-6/admin${originParams}&${newVinculateParams()}`,
  MLM: `/suscripciones/melimas/admin${originParams}&${newVinculateParams()}`,
};

export const pathExcludedSuscribe: Record<string, string> = {
  MLA: `/suscripciones${originParams}&${level6Params()}`,
  MCO: `/suscripciones${originParams}&${level6Params()}`,
};

export const MAX_LINK_ACTIVATE = '/suscripciones/max';
export const MAX_LINK_ACTIVATE_BR = '/assinaturas/max';
export const MAX_LINK_SUB = '/suscripciones/max';
export const MAX_LINK_SUB_BR = '/assinaturas/max';

export const availableAllsites = ['MCO', 'MLA', 'MLB', 'MLC', 'MLM'];

export const defaultState = {
  isVinculatedToMeliPlus: null,
  isUpgradeToMeliPlus: null,
  isReadyMeliPlus: null,
};

export const suscriptionsTrackName = {
  loyaltyTrack: {
    watch: 'loyalty-watch',
    linking: 'loyalty-linking',
    upgrade: 'loyalty-upgrade',
    activation: 'loyalty-activation',
    suscription: 'loyalty-suscription',
  },
  samplingTrack: {
    watch: 'sampling-watch',
    linking: 'sampling-linking',
    suscription: 'sampling-suscription',
  },
};
