import type { UseCardTrackingProps } from '../viewport-visibility.types';

import { useEffect, useContext } from 'react';

import { useInView } from 'react-intersection-observer';
import { v4 as uuidv4 } from 'uuid';

import { useViewport } from '../viewport-visibility';
import { HubContext } from '../../../pages/hub/context';

export const useCardTracking = (props: UseCardTrackingProps) => {
  const { addCard, initializePayload } = useViewport();
  const { filter } = useContext(HubContext);
  const {
    carouselName: carousel_name,
    carouselPosition: carousel_position,
    cardPosition,
    contentId: content_id,
    contentType: content_type,
    selectedTab: selected_tab,
  } = props;
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const isTrackingActive = false;

  useEffect(() => {
    if (inView && isTrackingActive) {
      const viewport_id = uuidv4();

      addCard({
        card_position: String(cardPosition),
        start_time: Date.now(),
        content_id,
        ...(carousel_name && { carousel_name }),
        ...(carousel_position && { carousel_position }),
        ...(content_type && { content_type }),
        ...(selected_tab && { selected_tab }),
      });

      initializePayload({
        viewport_id,
        start_time: Date.now(),
      });
    }
  }, [inView, filter]);

  return { inViewRef };
};
