import type { useInitialMetadataViewportProps } from './useInitialMetadataViewport.types';

import { useMemo } from 'react';

import { Utils } from '../../utils/utils';
import constants from '../../constants/commons.json';

const { COOKIE_NAMES } = constants;

export const useInitialMetadataViewport = (
  props: useInitialMetadataViewportProps,
) => {
  const {
    device,
    keyword,
    userId: propsUserId,
    headerOptions,
    siteId: propsSiteId,
    sourceView: source_view,
    contentId: content_id,
    platform,
  } = props;

  return useMemo(() => {
    const headerUserId: string | undefined = headerOptions?.userId;
    const user_id = propsUserId || headerUserId || null;

    const site_id = platform?.siteId || propsSiteId;

    const melidata_session_id = Utils.getCookieValue(COOKIE_NAMES.MELIDATA_ID);
    const deviceId = Utils.getCookieValue(COOKIE_NAMES.D2ID);

    return {
      device_platform: device?.type,
      device_os: device?.osName,
      device_dimensions: device?.viewportWidth,
      user_id,
      melidata_session_id: melidata_session_id || null,
      device_id: deviceId || null,
      source_view,
      site_id,
      ...(content_id && { content_id }),
      ...(keyword && { keyword }),
    };
  }, [device, keyword, propsUserId, headerOptions, content_id]);
};
