import { useEffect, useState } from 'react';

const BreakpointsSlides = {
  XS: 4,
  SM: 5,
  MD: 6,
  LG: 7,
  XL: 8,
};

const breakpoints = [
  { maxWidth: 576, slides: BreakpointsSlides.XS },
  { maxWidth: 768, slides: BreakpointsSlides.SM },
  { maxWidth: 992, slides: BreakpointsSlides.MD },
  { maxWidth: 1200, slides: BreakpointsSlides.LG },
];

const getSlidesForWidth = (width: number): number => {
  const breakpoint = breakpoints.find(
    (breakpoint) => width < breakpoint.maxWidth,
  );

  return breakpoint ? breakpoint.slides : BreakpointsSlides.XL;
};

export const useSlidesPerView = (enabled: boolean) => {
  const isServer = typeof window === 'undefined';

  const [slides, setSlides] = useState<number>(() =>
    enabled && !isServer
      ? getSlidesForWidth(window.innerWidth)
      : BreakpointsSlides.MD,
  );

  useEffect(() => {
    if (!enabled || isServer) {
      return;
    }

    const handleResize = () => {
      const newSlides = getSlidesForWidth(window.innerWidth);

      setSlides((prevSlides) =>
        prevSlides !== newSlides ? newSlides : prevSlides,
      );
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', handleResize);
  }, [enabled]);

  return { slides };
};
