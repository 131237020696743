import type { FC, MutableRefObject } from 'react';
import type { StickyScrollContainerProps } from './StickyScrollContainer.types';
import type { ContextValue } from '../../pages/hub/context';
import type { KnownComponent } from '../../../types/components';

import { useMemo, useRef, useContext } from 'react';

import classNames from 'classnames';

import { ScrollDirection } from './StickyScrollContainer.types';
import { useScrollDirListener } from './hook/useScrollDirListener';
import { HubContext } from '../../pages/hub/context';

const hasMainSliderAsFirstComponent = (data?: Array<KnownComponent>) => {
  const hasData = data && data.length > 0;

  if (!hasData) {
    return false;
  }

  const firstComponentProps = data[0].props;
  const isCarousel = 'carouselType' in firstComponentProps;

  if (!isCarousel) {
    return false;
  }

  return firstComponentProps.carouselType === 'main_slider';
};

export const StickyScrollContainer: FC<StickyScrollContainerProps> = ({
  children,
  className,
  component: Component = 'aside',
}) => {
  const { filter, data } = useContext<ContextValue>(HubContext);
  const stickyContainerRef = useRef<HTMLElement>();

  const { scrollDir } = useScrollDirListener(
    stickyContainerRef as MutableRefObject<HTMLElement>,
  );

  const hasFilter = useMemo(() => filter && filter !== '', [filter]);
  const hasMainSlider = useMemo(
    () => hasMainSliderAsFirstComponent(data),
    [data],
  );

  const containerClassName = useMemo(
    () =>
      classNames([
        className,
        'sticky-scroll__container',
        {
          'inactive-scroll': scrollDir === ScrollDirection.DOWN,
          'active-scroll': scrollDir === ScrollDirection.UP,
          'remove-padding-bottom': hasMainSlider && !hasFilter,
        },
      ]),
    [className, scrollDir, hasMainSlider, hasFilter],
  );

  return (
    <Component ref={stickyContainerRef} className={containerClassName}>
      {children}
    </Component>
  );
};
