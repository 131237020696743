import type { RestrictionConfirmation } from '../../../context/restrictions-store/restriction.store';
import type { RestrictionProps } from './Restriction.type';

import { type FC } from 'react';

import { Modal as AndesModal } from '@andes/modal';
import { Button } from '@andes/button';
import { Snackbar } from '@andes/snackbar';
import { useI18n } from 'nordic/i18n';

import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import paths from '../../../constants/paths-tracks.json';
import { ContentLockModal } from '../../webview/ContentLockModal';
import { NbcuMessage } from './components/NbcuMessage/NbcuMessage';
import { MaxQuotaMessage } from './components/MaxQuotaMessage/MaxQuotaMessage';
import { RatingAgeMessage } from './components/RagtingAgeMessage/RatingAgeMessage';

const { CONTENT_ACCESS_MODAL } = paths;

export const Restriction: FC<RestrictionProps> = ({
  children,
  titleCtaContinue,
  titleCtaCancel,
  title,
  type,
  fullWidthBtn = false,
  tracks,
  onContinue,
  className,
  currency,
  data,
  userContext,
}) => {
  const { request, snackbarVisible, setSnackbarVisible, permission } =
    useRestriction();

  const { i18n } = useI18n();

  const {
    hasNbcuRestriction,
    nbcuUserLoggedIn,
    isModalOpen,
    maxQuotaRestriction,
    hasRatingAge,
    hasGuestUserRestriction,
    nbcuUserGuest,
  } = permission;

  const handleRestrictionConfirmation = async (
    confirmation: RestrictionConfirmation,
  ) => {
    await request({
      content_access_confirmation: confirmation,
    });

    onContinue?.();

    window?.melidata?.('send', 'event', {
      path: CONTENT_ACCESS_MODAL,
      event_data: {
        context: tracks?.contentId ? 'vcp' : 'hub',
        content_access_confirmation: confirmation,
        vertical: 'mplay',
        ...(tracks?.contentId && { content_id: tracks.contentId }),
        ...(tracks?.guestUserId && {
          guest_user_id: tracks?.guestUserId,
        }),
      },
    });
  };

  const handleCancel = async () => handleRestrictionConfirmation('rejected');
  const handleContinue = async () => handleRestrictionConfirmation('accepted');

  const actions = {
    fixed: false,
    primary: (
      <Button fullWidth={fullWidthBtn} onClick={handleContinue}>
        {titleCtaContinue}
      </Button>
    ),
    secondary: (
      <Button fullWidth={fullWidthBtn} hierarchy="quiet" onClick={handleCancel}>
        {titleCtaCancel}
      </Button>
    ),
  };

  const renderRestrictions = () => {
    if (isModalOpen) {
      return (
        <AndesModal
          type={type}
          open={isModalOpen}
          title={title}
          actions={actions}
          closable={false}
          className={className}
        >
          {children}
        </AndesModal>
      );
    }

    if (hasNbcuRestriction) {
      return (
        <>
          <NbcuMessage
            isUserLoggedIn={nbcuUserLoggedIn && !nbcuUserGuest}
            data={data}
            currency={currency || ''}
            userContext={userContext}
          />
          <ContentLockModal
            data={data}
            currency={currency || ''}
            userContext={userContext}
          />
        </>
      );
    }

    if (hasGuestUserRestriction || maxQuotaRestriction) {
      return <MaxQuotaMessage />;
    }

    // TODO: handle geolocation restriction
    // if (restrictions.includes(RESTRICTIONS_VALUES.GEOLOCATION)) {
    //   return ...
    // }

    if (hasRatingAge) {
      return <RatingAgeMessage />;
    }

    return null;
  };

  return (
    <>
      {renderRestrictions()}
      <Snackbar
        message={i18n.gettext(
          'No pudimos guardar tu configuración, inténtalo nuevamente.',
        )}
        show={snackbarVisible}
        color="red"
        delay={5000}
        onClose={() => setSnackbarVisible(false)}
      />
    </>
  );
};
